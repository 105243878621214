
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref, inject, onUnmounted } from "vue";
import moment from "moment";
import { Socket } from "socket.io-client";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "notifications-menu",
  components: {},
  setup() {
    const notifications = ref<any[]>([]);
    const parentId = window.localStorage.getItem("parentId");
    const store = useStore();
    const { t } = useI18n();
    const socket: Socket = inject("socket") as Socket;

    const getNotificationLink = (notif: any) => {
      switch (notif.refType) {
        case "newHomework":
          return "/devoirs";
        case "newCourse":
          return "/cours";
        case "newBulletin":
          return "/bulletin";
        case "StudentPunishmentAccepted":
          return "/";
        case "adminMessage":
          return "/chat";
        case "newActuality":
          return "/actualites";
        case "newEvent":
          return "/calendar";
        case "newPayment":
          return "/paiement";
        case "newRegistration":
          return "/";
        case "attendance":
          return "/attendance";
        default:
          return "/";
      }
    };

    const updateData = () => {
      ApiService.get("/notifications")
        .then(({ data }) => {
          notifications.value = data.notifications
            .slice(0, 5) // Take only first 5 items
            .map((item) => ({
              ...item,
              link: getNotificationLink(item),
            }));
        })
        .catch((e) => console.log(e));
    };

    updateData();

    const handleNotification = (notification: any) => {
      if (notification.users.includes(parentId)) {
        const formattedNotification = {
          ...notification,
          link: getNotificationLink(notification),
        };
        notifications.value.unshift(formattedNotification);
      }
    };

    socket.on("attendance", handleNotification);
    socket.on("newHomework", handleNotification);
    socket.on("newCourse", handleNotification);
    socket.on("newBulletin", handleNotification);
    socket.on("StudentPunishmentAccepted", handleNotification);
    socket.on("adminMessage", handleNotification);
    socket.on("newActuality", handleNotification);
    socket.on("newEvent", handleNotification);
    socket.on("newPayment", handleNotification);
    socket.on("newRegistration", handleNotification);

    onUnmounted(() => {
      socket.off("attendance");
      socket.off("newHomework");
      socket.off("newCourse");
      socket.off("newBulletin");
      socket.off("StudentPunishmentAccepted");
      socket.off("adminMessage");
      socket.off("newActuality");
      socket.off("newEvent");
      socket.off("newPayment");
      socket.off("newRegistration");
    });

    return {
      notifications,
      moment,
      t,
    };
  },
});
